<template>
    <v-container fluid class="down-top-padding">
        <BaseBreadcrumb :title="trans('router.admin.landingDesigns')" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
        <v-row>
            <v-col cols="12" sm="12">
                <landing-designs-table></landing-designs-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "index",
        components: {
            BaseBreadcrumb: () => import("@/components/commonComponents/BaseBreadcrumb"),
            LandingDesignsTable: () => import("@/components/admin/landingDesign/LandingDesignTable")
        },
        data(){
            return {
                loading: false,
                errors: null,
                breadcrumbs: [
                    {
                        text: this.trans("router.admin.admin"),
                        disabled: false,
                        to: {name:'admin'}
                    },
                    {
                        text: this.trans('router.admin.landingDesigns'),
                        disabled: true
                    }
                ]
            }
        },
    }
</script>
